<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()">EDR-<span>Manager</span></a>
  </div>
</div>
<div class="header-container">
  <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
    <nb-option *ngFor="let theme of themes" [value]="theme.value">
      {{ theme.name }}</nb-option>
  </nb-select>
  <div class="notification-popup" [@fadeInDown]="fadeInDown" *ngIf="showNotifications" id="notification-popup">
    <nb-card [nbSpinner]="notificationLoading" nbSpinnerStatus="success">
      <nb-card-header class="cursor-default">Notifications
        <nb-badge status="info" position="bottom left" class="badge" text="{{ notificationsCount }} unread">
        </nb-badge>
      </nb-card-header>
      <nb-card-body class="max-height">
        <div class="notification-data clearfix pt-2 pb-1" *ngFor="let notification of notifications">
          <a [ngClass]="
              notification?.message ? 'cursor-pointer' : 'cursor-default'
            " (click)="openNotification(notification?.orderId, notification?._id)">
            <span class="d-inline-block text-truncate" style="max-width: 50vw; width: 300px">
              {{ notification?.message }}
            </span>
            <br />
            <p class="float-end">
              {{ notification?.createdAt | dateAgo }}
            </p>
          </a>
          <nb-badge *ngIf="!notification?.isRead" status="info" position="bottom left" class="badge" text="Unread">
          </nb-badge>
        </div>
        <div class="notification-data clearfix pt-2 pb-1" *ngIf="!notifications?.length">
          <div class="cursor-default">
            <span> No Notifications </span>
          </div>
        </div>
      </nb-card-body>
      <nb-card-footer class="btn" (click)="notificationPage()">View All Notifications</nb-card-footer>
    </nb-card>
  </div>
  <nb-actions size="medium">
    <nb-action *ngIf="notifications" (click)="toggleNotifications()" title="My Notifications" class="control-item"
      icon="bell-outline" badgeText="{{ notificationsCount }}" badgePosition="top right" badgeStatus="danger">
    </nb-action>
    <nb-action class="user-action" *nbIsGranted="['view', 'user']">
      <nb-user [nbContextMenu]="userMenu" [onlyPicture]="userPictureOnly" name="Manager">
      </nb-user>
    </nb-action>
  </nb-actions>
</div>