import { transition, trigger, useAnimation } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth/auth.service';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';
import { CommunicatorService } from '@services/communicator/communicator.service';
import { MessagebusService } from '@services/messagebus/messagebus.service';
import { ApiReturn, getNotificationJson, setNotificationsReadJson } from '@services/standard';
import { fadeIn, fadeOut } from 'ng-animate';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
  animations: [
    trigger('fadeInDown', [
      transition(':enter', [useAnimation(
        fadeIn,
        {
          params: { timing: 0.1, delay: 0 },
        },
      )]
      ),
      transition(':leave', [useAnimation(
        fadeOut,
        {
          params: { timing: 0.1, delay: 0 },
        },
      )]
      )
    ],
    ),
  ],
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;
  showNotifications: boolean = false;
  notificationLoading: boolean = true;
  notificationsCount: any = '';
  notifications = [];
  pageSize = 10;
  pageNo = 1;

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';

  userMenu = [{ title: 'Profile' }, { title: 'Log out' }];

  constructor(private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private userService: UserData,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    private communicatorService: CommunicatorService,
    private authService: AuthService,
    private router: Router,
    private messagebusService: MessagebusService,
  ) {
    menuService.onItemClick().subscribe(items => {
      if (items?.item === this.userMenu[1]) {
        this.authService.logout();
      } else if (items?.item === this.userMenu[0]) {
        this.router.navigate(['/manager/profile'])
      }
    })
  }

  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;
    // this.setDisplayData();
    this.refreshHeaderData();
    this.messagebusService.getNotifications().subscribe(() => {
      this.refreshHeaderData();
    });
    // this.currentTheme = this.themeService.currentTheme;

    this.userService.getUsers()
      .pipe(takeUntil(this.destroy$))
      .subscribe((users: any) => this.user = users.nick);

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);
  }

  toggleNotifications() {
    this.showNotifications = !this.showNotifications;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();
    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  refreshHeaderData() {
    this.getNotifications();
  }

  getNotifications() {
    this.notificationLoading = true;
    const GetNotificationsJson = Object.assign({}, getNotificationJson);
    const params = `?page=${this.pageNo}&perPage=${this.pageSize}`;
    GetNotificationsJson.url = `${GetNotificationsJson.url}${params}`;
    this.communicatorService.ApiRunner(GetNotificationsJson).subscribe({
      next: (apiReturn: ApiReturn) => {
        if (this.communicatorService.responseParser(apiReturn)) {
          if (apiReturn?.data[0]?.unread[0]?.total_rows) {
            this.notificationsCount = apiReturn?.data[0].unread[0].total_rows;
          } else {
            this.notificationsCount = 0;
          }
          this.notifications = apiReturn?.data[0]?.data;
        } else {
          this.notifications = [];
          this.notificationsCount = 0;
        }
      },
      error: (err: any) => {
      },
      complete: () => {
        this.notificationLoading = false;
      },
    });
  }

  notificationPage() {
    this.toggleNotifications();
    this.router.navigate(['manager/notification']);
  }

  openNotification(orderId, id) {
    const SetNotificationsReadJson = setNotificationsReadJson;
    setNotificationsReadJson._id = id
    this.communicatorService.ApiRunner(SetNotificationsReadJson).subscribe({
      next: (apiReturn: ApiReturn) => {
        if (this.communicatorService.responseParser(apiReturn)) {
        }
      },
      error: (err: any) => {
      },
      complete: () => {
        this.getNotifications();
        this.router.navigate([`manager/order/detail/${orderId}`]);
      },
    });
  }
}
