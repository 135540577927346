import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';


function sentryInit() {
  Sentry.init({
    environment: environment.environment,
    dsn: "https://34860a250b4d403fbd2cebf0e87993f1@o1096075.ingest.sentry.io/6116622",
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: ["localhost", "https://yourserver.io/api"],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],
    tracesSampleRate: environment.tracesSampleRate,
  });
}
if (environment.sentry) {
  sentryInit();
}
if (environment.production) {
  enableProdMode();
  if (window) {
    window.console.log = window.console.warn = window.console.info = function () {
      // Don't log anything.
    };
  }
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
