

export const helpTitle = " EDR Manager";
export const helpMessage = "Api throws error ";
export const notFoundMessage = "Requested Api not available.!";

export function parseJwt(token) {
    if (token && token.length > 0) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        return JSON.parse(jsonPayload);
    } else {
        return null;
    }
};

export class ApiReturn {
    status: boolean;
    message: string;
    DetailedMessage?: string;
    StatusCode?: StatusCode;
    data?: any;
    token?: string;
    UserToken?: string;
    unRead?: number;
    total?: number;
}



export enum colorHelper {
    basic = "basic",
    primary = "primary",
    success = "success",
    info = "info",
    warning = "warning",
    danger = "danger",
    control = "control"
}



export enum StatusCode {
    OK = "OK", NotFound = "NotFound", InternalServerError = "InternalServerError", InvalidLoginError = "InvalidLoginError"
}
export enum RestMethod {
    get, post, put, update, delete
}

export enum OrderStatus {
    accepted, rejected, prepared
}

export class EdrRestApiJson {
    url: string;
    restMethod: RestMethod;
    authentication: boolean;
    pathParams?: string;
    password?: string;
    username?: string;
    email?: string;
    restaurentname?: string;
    name?: string;
    mobile?: string;
    cuisines?: string;
    opening_time?: string;
    closing_time?: string;
    address?: {
        longitude?: string;
        latitude?: string;
        house?: string;
        locality?: string;
        area?: string;
        city?: string;
        pincode?: string;
    }
    restaurant_id?: string;
    max?: string;
    off?: string;
    validity?: string;
    is_flat?: string;
    orderStatus?: string;
    priority?: boolean;
    securityToken?: string;
    newPassword?: string;
    oldPassword?: string;
    orderId?: string;
    deliveryboyId?: string;
    product_id?: string;
    price?: number;
    discount_type?: string;
    is_discount?: boolean;
    discount?: number;
    available_quantity?: number;
    status?: boolean;
    item_id?: string;
    collectAmount?: number;
    categoryId?: string;
    category?: string;
    out_of_stock?: boolean;
    _id?: string;
    firebasedeviceId?: string;
    deviceType?: string;
}


export class MessageData {
    public UserData?: any;
    public Token?: string;
    public UserToken?: string;
    public UserName?: string;
    public Role?: string;

}

export const postLoginJson: EdrRestApiJson = {
    url: 'v1/manager/login',
    restMethod: RestMethod.post,
    authentication: false,
    username: "",
    password: ""
};

export const postForgetPasswordJson: EdrRestApiJson = {
    url: 'v1/manager/forgotPassword',
    restMethod: RestMethod.post,
    authentication: false,
    email: ""
};

export const postResetPasswordJson: EdrRestApiJson = {
    url: 'v1/manager/resetPassword',
    restMethod: RestMethod.post,
    authentication: false,
    pathParams: '',
    securityToken: '',
    newPassword: ''
};

export const changePasswordJson: EdrRestApiJson = {
    url: 'v1/manager/changePassword',
    restMethod: RestMethod.put,
    authentication: true,
    oldPassword: '',
    newPassword: ''
};

export const getProfileJson: EdrRestApiJson = {
    url: 'v1/manager/profile',
    restMethod: RestMethod.get,
    authentication: true
};

export const updateProfileJson: EdrRestApiJson = {
    url: 'v1/manager/profile',
    restMethod: RestMethod.put,
    authentication: true,
    username: "",
    name: "",
    email: "",
    mobile: ""
}

export const getDeveliveryBoyJson: EdrRestApiJson = {
    url: 'v1/manager/deliveryboys',
    restMethod: RestMethod.get,
    authentication: true
}

export const getDeliveryBoyDetailsJson: EdrRestApiJson = {
    url: 'v1/manager/deliveryboys',
    restMethod: RestMethod.get,
    authentication: true,
    pathParams: ''
}

export const postDeliveryBoyJson: EdrRestApiJson = {
    url: 'v1/manager/deliveryboys',
    restMethod: RestMethod.post,
    authentication: true,
    name: '',
    email: '',
    mobile: ''
};

export const updateDeliveryBoyDetailsJson: EdrRestApiJson = {
    url: 'v1/manager/deliveryboys',
    restMethod: RestMethod.put,
    authentication: true,
    pathParams: '',
    name: '',
    email: '',
    mobile: ''
}

export const deleteDeliveryBoyJson: EdrRestApiJson = {
    url: 'v1/manager/deliveryboys',
    restMethod: RestMethod.delete,
    authentication: true,
    pathParams: ''
}

export const collectDeliveryBoyJson: EdrRestApiJson = {
    url: 'v1/manager/deliveryboys',
    restMethod: RestMethod.put,
    authentication: true,
    collectAmount: 0
    // pathParams: ''
}


export const resetDeliveryBoyPasswordJson: EdrRestApiJson = {
    url: 'v1/manager/deliveryboys/reset',
    restMethod: RestMethod.put,
    authentication: true,
    email: ''
}

export const getOrderJson: EdrRestApiJson = {
    url: 'v1/manager/orders',
    restMethod: RestMethod.get,
    authentication: true
}

export const getOrderDetailsJson: EdrRestApiJson = {
    url: 'v1/manager/orders',
    restMethod: RestMethod.get,
    authentication: true,
    pathParams: ''
}

export const updateOrderJson: EdrRestApiJson = {
    url: 'v1/manager/orders',
    restMethod: RestMethod.put,
    authentication: true,
    pathParams: '',
    orderStatus: '',
}

export const prepareOrderJson: EdrRestApiJson = {
    url: 'v1/manager/orders/prepareOrder',
    restMethod: RestMethod.put,
    authentication: true,
    pathParams: '',
    orderStatus: '',
}

export const assignOrderDeliveryBoyJson: EdrRestApiJson = {
    url: 'v1/manager/orders',
    restMethod: RestMethod.put,
    authentication: true,
}

export const deleteOrderDeliveryBoyJson: EdrRestApiJson = {
    url: 'v1/manager/orders',
    restMethod: RestMethod.delete,
    authentication: true,
}

export const getRestaurantJson: EdrRestApiJson = {
    url: 'v1/manager/all/shop',
    restMethod: RestMethod.get,
    authentication: true
}

export const getProductJson: EdrRestApiJson = {
    url: 'v1/manager/products',
    restMethod: RestMethod.get,
    authentication: true
}

export const deleteProductJson: EdrRestApiJson = {
    url: 'v1/manager/product/delete',
    restMethod: RestMethod.delete,
    authentication: true
}

export const getProductDetailsJson: EdrRestApiJson = {
    url: 'v1/manager/product/show',
    restMethod: RestMethod.get,
    authentication: true,
    pathParams: ''
}

export const postProductJson: EdrRestApiJson = {
    url: 'v1/manager/products',
    restMethod: RestMethod.post,
    authentication: true,
    product_id: '',
    category: '',
    price: 0,
    discount_type: '',
    is_discount: false,
    discount: 0,
    available_quantity: 0,
    status: false,
    out_of_stock: false
}

export const updateProductDetailsJson: EdrRestApiJson = {
    url: 'v1/manager/products',
    restMethod: RestMethod.put,
    authentication: true,
    item_id: '',
    product_id: '',
    category: '',
    price: 0,
    discount_type: '',
    is_discount: false,
    discount: 0,
    available_quantity: 0,
    status: false,
    out_of_stock: false
}

export const getGenericProductJson: EdrRestApiJson = {
    url: 'v1/manager/generic-product/all',
    restMethod: RestMethod.get,
    authentication: true
}

export const getGenericProductAllJson: EdrRestApiJson = {
    url: 'v1/manager/generic-product-all',
    restMethod: RestMethod.get,
    authentication: true
}

export const getOfferJson: EdrRestApiJson = {
    url: 'v1/manager/offers',
    restMethod: RestMethod.get,
    authentication: true
}

export const getCustomerDetailsJson: EdrRestApiJson = {
    url: 'v1/manager/customers',
    restMethod: RestMethod.get,
    authentication: true,
    pathParams: ''
}

export const getCategoryJson: EdrRestApiJson = {
    url: 'v1/manager/category/all',
    restMethod: RestMethod.get,
    authentication: true,
}

export const deleteCategoryJson: EdrRestApiJson = {
    url: 'v1/manager/category/delete',
    restMethod: RestMethod.delete,
    authentication: true,
    pathParams: ''
}

export const getCategoryDetailJson: EdrRestApiJson = {
    url: 'v1/manager/category',
    restMethod: RestMethod.get,
    authentication: true,
    pathParams: ''
}

export const postCategoryJson: EdrRestApiJson = {
    url: 'v1/manager/category/create',
    restMethod: RestMethod.post,
    authentication: true,
    name: ''
}


export const postFbTokenJson: EdrRestApiJson = {
    url: 'v1/manager/post-user-token',
    restMethod: RestMethod.post,
    authentication: true,
    firebasedeviceId: '',
    deviceType: '',

}

export const updateCategoryJson: EdrRestApiJson = {
    url: 'v1/manager/category/update',
    restMethod: RestMethod.put,
    authentication: true,
    categoryId: '',
    name: ''
}

export const getRestaurentJson: EdrRestApiJson = {
    url: 'v1/manager/shop',
    restMethod: RestMethod.get,
    authentication: true
};

export const getShopRatingJson: EdrRestApiJson = {
    url: 'v1/manager/shop-rating',
    restMethod: RestMethod.get,
    authentication: true,
    pathParams: ''
};

export const getCuisinesJson: EdrRestApiJson = {
    url: 'v1/manager/cuisines/all',
    restMethod: RestMethod.get,
    authentication: true
};

export const updateRestaurentJson: EdrRestApiJson = {
    url: 'v1/manager/shop',
    restMethod: RestMethod.put,
    authentication: true,
    restaurentname: "",
    cuisines: "",
    email: "",
    mobile: ""
}
export const postRestaurantJson: EdrRestApiJson = {
    url: 'v1/manager/add/shop',
    restMethod: RestMethod.post,
    authentication: true,
    name: "",
    mobile: "",
    email: "",
    cuisines: "",
    opening_time: "",
    closing_time: "",
    address: {
        longitude: "",
        latitude: "",
        house: "",
        city: "",
        locality: "",
        area: "",
        pincode: "",
    }
}


export const postOfferJson: EdrRestApiJson = {
    url: 'v1/manager/offer/add',
    restMethod: RestMethod.post,
    authentication: true,
    restaurant_id: "",
    max: "",
    off: "",
    validity: "",
    is_flat: "",
}

export const getUserTypeJson: EdrRestApiJson = {
    url: 'v1/manager',
    restMethod: RestMethod.get,
    authentication: true
}

export const getOrderJsonV2: EdrRestApiJson = {
    url: 'v2/manager/orders',
    restMethod: RestMethod.get,
    authentication: true,
    pathParams: ''
}

export const getShopsReportJson: EdrRestApiJson = {
    url: 'v1/manager/shops-reports',
    restMethod: RestMethod.get,
    authentication: true,
    pathParams: ''
}

export const getNotificationJson: EdrRestApiJson = {
    url: 'v1/manager/notifications',
    restMethod: RestMethod.get,
    authentication: true,
    pathParams: ''
}

export const setNotificationsReadJson: EdrRestApiJson = {
    url: "v1/manager/notifications",
    restMethod: RestMethod.post,
    authentication: true,
    _id: '',
};