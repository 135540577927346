import 'rxjs/add/operator/do';
import 'rxjs/add/operator/map';

import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@auth/auth.service';
import { ToastService } from '@services/toastservice/toastservice.service';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { ApiReturn, EdrRestApiJson, RestMethod, StatusCode } from '../standard';





const baseUrl: string = environment.baseUrl;
@Injectable({
  providedIn: 'root'
})
export class CommunicatorService {



  constructor(
    private http: HttpClient,
    private toasterService: ToastService,
    private authService: AuthService) { }

  ApiRunner(apiJson: EdrRestApiJson): Observable<ApiReturn> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json');
    if (apiJson.authentication) {
      headers = headers.set('Authorization', `Bearer ${this.authService.token}`)
    }
    console.log("api call triggered with:", JSON.stringify(apiJson));
    if (apiJson.url == "") {
      const apiReturn: ApiReturn = {
        message: "No url specified. Please contact support!",
        status: false,
        StatusCode: StatusCode.InternalServerError
      };
      return of(apiReturn);
    }
    var url = `${baseUrl}${apiJson.url}`;
    if (apiJson.pathParams) {
      url = `${url}/${apiJson.pathParams}`;
    }
    if (apiJson.restMethod === RestMethod.get) {
      const apiReturn = this.http
        .get(url, { headers: headers })
        .do(console.log)
        .pipe(catchError(this.errorHandler));
      return apiReturn;
    }
    else if (apiJson.restMethod === RestMethod.post) {
      const apiReturn = this.http
        .post<ApiReturn>(url, apiJson, { headers: headers })
        .pipe(catchError(this.errorHandler)); // catch error
      return apiReturn;
    }
    else if (apiJson.restMethod === RestMethod.put) {
      const apiReturn = this.http
        .put<ApiReturn>(url, apiJson, { headers: headers })
        .pipe(catchError(this.errorHandler));
      return apiReturn;
    }
    else if (apiJson.restMethod === RestMethod.delete) {
      const apiReturn = this.http
        .delete<ApiReturn>(url, { headers: headers })
        .pipe(catchError(this.errorHandler));
      return apiReturn;
    }
  }

  errorHandler(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.log("An error occurred: " + error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.log(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
      console.log("Api returned", JSON.stringify(error.error));
    }
    try {
      // this.toasterService.showConfigError();
    } catch (exception) {
      console.log(exception);
    }
    // return an observable with a user-facing error message
    return throwError(error.error);
  }

  responseParser(apiReturn: ApiReturn) {
    if (apiReturn.status == true) {
      return true;
    } else if (apiReturn.StatusCode == StatusCode.NotFound) {
      this.toasterService.showNotFoundInApi();
      return false;
    } else if (apiReturn.StatusCode == StatusCode.InvalidLoginError) {
      this.toasterService.showInvalidLogin();
      return false;
    }
    else {
      // todo: some other status coming here
      // this.showConfigError();
    }
  }





}
