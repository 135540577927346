import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private router: Router) { }
  get token() {
    return JSON.parse(localStorage.getItem('manager'));
  }
  logout() {
    localStorage.removeItem('manager');
    this.router.navigate(['/login']);
  }
}
