import { Injectable } from '@angular/core';
import { NbGlobalLogicalPosition, NbIconConfig, NbToastrService } from '@nebular/theme';

import { colorHelper, helpMessage, helpTitle, notFoundMessage } from '../standard';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(private toastrService: NbToastrService) { }
  showConfigError() {
    try {
      this.toastrService.show(helpMessage, helpTitle, {
        preventDuplicates: true,
        destroyByClick: true,
        status: colorHelper.danger,
        // duration: 3000,
        limit: 3, position: NbGlobalLogicalPosition.BOTTOM_END
      });
    } catch (Ex) {
      console.log(Ex);
    }
  }

  showNotFoundInApi() {
    this.toastrService.show(notFoundMessage, helpTitle, {
      preventDuplicates: true,
      destroyByClick: true,
      status: colorHelper.danger,
      // duration: 3000,
      limit: 3, position: NbGlobalLogicalPosition.BOTTOM_END
    });
  }
  showSuccess(message) {
    this.toastrService.show(message, helpTitle, {
      preventDuplicates: true,
      destroyByClick: true,
      status: colorHelper.success,
      duration: 5000,
      limit: 3, position: NbGlobalLogicalPosition.TOP_END
    })
  }
  showError(message) {
    this.toastrService.show(message, helpTitle, {
      preventDuplicates: true,
      destroyByClick: true,
      status: colorHelper.danger,
      duration: 5000,
      limit: 3, position: NbGlobalLogicalPosition.TOP_END
    })
  }

  showInvalidLogin() {
    this.toastrService.show("Invalid login credentials try again!", helpTitle, {
      preventDuplicates: true,
      destroyByClick: true,
      status: colorHelper.danger,
      limit: 3, position: NbGlobalLogicalPosition.BOTTOM_END
    });
  }
  showNotification(message: string, audio?: HTMLAudioElement) {
    const iconConfig: NbIconConfig = { icon: 'shopping-cart-outline', pack: 'eva' };
    const toastRef = this.toastrService.show(message, helpTitle, {
      preventDuplicates: true,
      destroyByClick: true,
      status: colorHelper.info,
      duration: 0,
      position: NbGlobalLogicalPosition.TOP_END,
      icon: iconConfig
    });
    if (audio) {
      toastRef['toastContainer']?.nativeElement?.addEventListener('click', () => {
        audio.pause();
      });
    }
  }
}
