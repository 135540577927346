import { Component, OnInit } from '@angular/core';

import packageJson from '../../package.json';

@Component({
  selector: 'ngx-app',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit {
  public version: string = packageJson.version;
  constructor(
  ) {
  }

  ngOnInit(): void {
    console.log(`Made with \u{2764} at VAAS ITS`);
    console.log(`App version ${this.version} \u{1F604}`);
  }
}
